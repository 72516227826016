<template>
  <div>
    <div v-if="message != null" class="alert alert-success" role="alert">
      {{message}}
    </div>

    <div v-if="error != null" class="alert alert-danger" role="alert">
      {{error}}
    </div>

    <div class="row">
      <div class="col-md-4">
        <file-uploader v-model="fileId"></file-uploader>
      </div>
      <div class="col-md-4">
        <file-id v-if="fileId !== null" v-model="fileId" :advisorCodes="advisorCodes" @input="updateFileIds"></file-id>
      </div>
      <div class="col-md-4">
        <div class="btn-toolbar">
          <div class="btn-group pull-right">
            <button type="button" class="btn btn-info" @click="showInstructions"><i class="fa fa-question"></i>&nbsp;Instructions</button>
            <button type="button" class="btn btn-default" :disabled="identifiedFileUploads.length === 0" @click="downloadIdentified"><i class="fa fa-download"></i>&nbsp;Download</button>
            <button v-if="1==0" type="button" class="btn btn-success" :disabled="identifiedFileUploads.length === 0" @click="promptSubmitIdentified"><i class="fa fa-link"></i>&nbsp;Submit</button>
            <button type="button" class="btn btn-danger" :disabled="identifiedFileUploads.length === 0" @click="promptDeleteAll"><i class="fa fa-trash"></i>&nbsp;Delete All</button>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div class="row">
      <div class="col-md-4">
        <file-upload 
          v-model="unidentifiedFileUploads[index]" 
          v-for="(fileUpload, index) in unidentifiedFileUploads" 
          :key="fileUpload.id"
          @preview="showPreview(fileUpload)"
          @delete="promptDelete(fileUpload)"
        >
        </file-upload>      
      </div>

      <div class="col-md-2">
        <file-upload-template 
          v-if="index <= fileUploadTemplates.length / 2"
          v-model="fileUploadTemplates[index]" 
          v-for="(fileUploadTemplate, index) in fileUploadTemplates" 
          :key="fileUploadTemplate.id"
          @update="updateFileUpload"
        >
        </file-upload-template>
      </div>  

      <div class="col-md-2">
        <file-upload-template 
          v-if="index > fileUploadTemplates.length / 2"
          v-model="fileUploadTemplates[index]" 
          v-for="(fileUploadTemplate, index) in fileUploadTemplates" 
          :key="fileUploadTemplate.id"
          @update="updateFileUpload"
        >
        </file-upload-template>
      </div>  

      <div class="col-md-4">
        <file-upload 
          v-model="identifiedFileUploads[index]" 
          v-for="(fileUpload, index) in identifiedFileUploads" 
          :key="fileUpload.id" 
          @preview="showPreview(fileUpload)"
          @delete="promptDelete(fileUpload)"
          @submit="promptSubmit(fileUpload)"
          @input="updateFileUpload(fileUpload.id, { filename: fileUpload.filename })"
        >
        </file-upload>      
      </div>
    </div>

    <div id="preview-modal" class="modal fade" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          <h3 id="myModalLabel"><span v-if="currentUpload">{{currentUpload.filename}}</span></h3>
        </div>

        <div class="modal-content">
          <img v-if="currentUpload" :src="currentUpload.attachment.preview_url" :alt="currentUpload.attachment.filename" class="img-thumbnail">
        </div>

        <div class="modal-footer">
          <button class="btn" data-dismiss="modal" aria-hidden="true">Close</button>
        </div>        
      </div>
    </div>

    <div id="instructions-modal" class="modal fade" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
          <h3 id="myModalLabel">Instructions</h3>
        </div>

        <div class="modal-content">
          <ol>
            <li><b>Create the FileID.</b> Use the green checkmark to lock it in.</li>
            <li><b>Drag and Drop</b> the raw uncompressed photos from your hard drive onto the <u>Drop Files Here</u> button. Thumbnail images will appear below in a few seconds.</li>
            <li><b>Drag and Drop</b> each file on top of the desired file NAME in the middle – it renames it and moves it to the right column.  Most of the time these short names it creates are all that's needed.  Long detailed names are not wanted. Where you do need to edit names (windows!) you can either edit them online (use the green checkmark to lock in an edited name), or download them and edit names on your PC.</li>
            <li>
              Download your compressed renamed photos in a zip.
              <ul>
                <li>Click <u>Delete All</u> on the right to clear all photos after downloading.</li>
                <li>Enter a new FileID to start a new set of photos, or just log off.</li>
              </ul>
            </li>
          </ol>
        </div>

        <div class="modal-footer">
          <button class="btn" data-dismiss="modal" aria-hidden="true">Close</button>
        </div>        
      </div>
    </div>    
  </div>
</template>

<script>
import FileId from './FileId.vue'
import FileUpload from './FileUpload.vue'
import FileUploadTemplate from './FileUploadTemplate.vue'
import FileUploader from './FileUploader.vue'
import csrf from '../mixins/csrfMixin.js'

export default {
  name: "FileUploadManager",
  components: {
    'file-id': FileId,
    'file-upload': FileUpload,
    'file-upload-template': FileUploadTemplate,
    'file-uploader': FileUploader
  },
  mixins: [csrf],
  data() {
    return {
      fileId: null,
      fileUploads: [],
      latestId: null,
      isLoading: false,
      fileUploadTemplates: [],
      message: null,
      error: null,
      currentUpload: null,
      users: []
    }
  },
  mounted() {
    Promise.all([this.getUsers(), this.getFileUploads(), this.getFileUploadTemplates()]).then((values) => {
      if(this.identifiedFileUploads.length > 0) {
        this.fileId = this.identifiedFileUploads[0].fileid
      } else {
        this.fileId = `11${this.advisorCodes[0]}D00001`
      }

      setInterval(function () {
        this.getFileUploads()
      }.bind(this), 5000)
    })
  },
  computed: {
    unidentifiedFileUploads () {
      return this.fileUploads.filter((fileUpload) => !fileUpload.identified)
    },
    identifiedFileUploads () {
      return this.fileUploads.filter((fileUpload) => fileUpload.identified).sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
    },
    advisorCodes () {
      return this.users.map((user) => user.advisor_code).filter(code => code).sort()
    }
  },
  methods: {
    updateFileIds() {
      this.fileUploads.forEach((fileUpload) => this.updateFileUpload(fileUpload.id, { fileid: this.fileId }))
    },
    async updateFileUpload (fileUploadId, params) {
      let url = `/file_uploads/${fileUploadId}`
      let headers = { 'X-CSRF-Token': this.csrfToken }
      
      this.$secured.put(url, { file_upload: params }, { headers: headers })
        .then((response) => {
          let fileUpload = this.fileUploads.find((fileUpload) => fileUpload.id == fileUploadId)
          let index = this.fileUploads.indexOf(fileUpload)

          if(index > -1) {
            this.fileUploads.splice(index, 1, response.data)
            this.message = `Updated ${fileUpload.attachment.filename}`
          }
        })
        .catch((error) => {
          this.message = `Failed to update file upload: ${error}`
          this.isLoading = false
        })   
    },
    async getFileUploads() {
      let url = `/file_uploads`
      let params = {}

      this.isLoading = true
      if(this.latestId != null) {
        params['after'] = this.latestId
      }

      await this.$secured.get(url, { params })
        .then((response) => {
          let newFileUploads = response.data

          this.isLoading = false            

          if(newFileUploads.length > 0) {
            this.fileUploads = newFileUploads.concat(this.fileUploads)
            this.latestId = Math.max(...this.fileUploads.map((fileUpload) => fileUpload.id))
          }
        })
        .catch((error) => {
          this.message = `Failed to get file uploads: ${error}`
          this.isLoading = false
        })       
    },
    async getUsers() {
      let url = `/users`

      await this.$secured.get(url)
        .then((response) => {
          this.users = response.data
        })
        .catch((error) => {
          this.error = `Failed to get users: ${error}`
          this.isLoading = false
        })       
    },
    async getFileUploadTemplates() {
      let url = `/file_upload_templates`

      await this.$secured.get(url)
        .then((response) => {
          this.fileUploadTemplates = response.data
        })
        .catch((error) => {
          this.error = `Failed to get file upload templates: ${error}`
          this.isLoading = false
        })       
    },
    showPreview (fileUpload) {
      this.currentUpload = fileUpload

      $('#preview-modal').modal("show")
    },
    showInstructions () {
      $('#instructions-modal').modal("show")
    },
    promptDelete (fileUpload) {
      if(confirm('Do you want to delete this file upload?')) {
        this.deleteFileUpload(fileUpload)
      }
    },
    promptDeleteAll () {
      if(confirm(`Do you want to delete all of the file uploads (${this.fileUploads.length})?`)) {
        this.fileUploads.forEach((fileUpload) => this.deleteFileUpload(fileUpload))
      }
    },
    promptSubmitIdentified () {
      if(confirm(`Do you want to submit the identified file uploads (${this.identifiedFileUploads.length})?`)) {
        this.identifiedFileUploads.forEach((fileUpload) => this.submitFileUpload(fileUpload))
      }
    },
    downloadIdentified () {
      let ids = this.identifiedFileUploads.map((fileUpload) => fileUpload.id)
      let url = `/file_uploads/download.zip?${ids.map((id) => 'ids[]=' + id).join('&')}`

      this.message = `Queued file download: ${this.identifiedFileUploads.length} files`

      fetch(url).then(res => res.blob()).then(file => {
        let tempUrl = URL.createObjectURL(file)
        const aTag = document.createElement("a")

        aTag.href = tempUrl
        aTag.download = `${this.fileId}.zip`
        document.body.appendChild(aTag)
        aTag.click()
        URL.revokeObjectURL(tempUrl)
        aTag.remove()
      }).catch((error) => {
        this.error = `Failed to download file: ${error}`
      });
    },    
    deleteFileUpload (fileUpload) {
      let url = `/file_uploads/${fileUpload.id}`
      let headers = { 'X-CSRF-Token': this.csrfToken }

      this.$secured.delete(url, { headers: headers })
        .then((response) => {
          let index = this.fileUploads.indexOf(fileUpload)

          if(index > -1) {
            this.fileUploads.splice(index, 1)
          }
          this.message = `Deleted file upload: ${fileUpload.filename}`
        })
        .catch((error) => {
          this.error = `Failed to delete file upload (${error.message})`
        })
    },
    promptSubmit (fileUpload) {
      if(confirm('Do you want to submit this file upload to ERS?')) {
        this.submitFileUpload(fileUpload)
      }
    },
    submitFileUpload (fileUpload) {
      let url = `/file_uploads/${fileUpload.id}/submit`
      let headers = { 'X-CSRF-Token': this.csrfToken }

      this.$secured.put(url, {}, { headers: headers })
        .then((response) => {
          response

          this.message = `Submitted file upload: ${fileUpload.filename}`
        })
        .catch((error) => {
          this.error = `Failed to submit file upload (${error.message})`
        })
    }
  }
}  
</script>

<style>
  div#preview-modal > div.modal-dialog > div.modal-header, div#preview-modal > div.modal-dialog > div.modal-footer {
    background-color: white;
  }

  div#instructions-modal > div.modal-dialog > div.modal-header, div#instructions-modal > div.modal-dialog > div.modal-footer {
    background-color: white;
  }
  div#instructions-modal > div.modal-dialog > div.modal-content {
    padding: 20px;

    li {
      margin: 10px 0;
    }
  }
</style>
