<template>
  <div class="input-group">
    <span class="input-group-addon">11</span>
    <div class="input-group-addon">
      <select v-model="advisor">
        <option v-for="advisorCode in advisorCodes" :key="advisorCode" :value="advisorCode">{{advisorCode}}</option>
      </select>
    </div>
    <div class="input-group-addon">
      <select v-model="programme">
        <option value="D">D</option>
        <option value="E">E</option>
      </select>
    </div>
    <file-suffix v-model="suffix"></file-suffix>
  </div>
</template>

<script>
import FileSuffix from './FileSuffix.vue'

export default {
  name: "FileId",
  components: {
    'file-suffix': FileSuffix
  },
  props: {
    value: String,
    advisorCodes: Array
  },
  data() {
    return {
      advisor: this.value.substring(2, 4),
      programme: this.value.substring(4, 5),
      suffix: this.value.substring(5, 10)
    }
  },
  watch: {
    fileId(newValue, oldValue) {
      if(newValue !== this.value) {
        this.$emit('input', newValue)
      }
    }
  },
  computed: {
    fileId() {
      return ['11', this.advisor, this.programme, this.suffix].join('')
    }
  }
} 
</script>