<template>
  <select :value="locationId" @change.prevent="onChange">
    <option v-for="location in locations" :key="location.id" :value="location.id">{{location.name}}</option>
  </select>
</template>

<script>
import csrf from '../mixins/csrfMixin.js'

export default {
  name: "LocationDropdown",
  props: {
    locationId: Number,
    evaluationId: Number,
    locations: Array
  },
  mixins: [csrf],
  mounted () {
    this.value = this.locationId
  },
  methods: {
    onChange (e) {
      if(confirm('Are you sure you want to change the location?')) {
        let locationId = e.target.value
        let url = `/evaluations/${this.evaluationId}`
        let params = { evaluation: { location_id: locationId } }
        let headers = { 'X-CSRF-Token': this.csrfToken }

        this.$secured.put(url, params, { headers: headers })
          .catch((error) => {
            let message = `Failed to update location: ${error}`
  
            alert(message) 
          }) 
      } else {
        e.preventDefault()
      }
    }
  }
}
</script>
