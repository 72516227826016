<template>
  <div>
  </div>
</template>

<script>
export default {
  name: "ShowEvaluation",  
  mounted() {
    let navTabs = document.querySelector('.nav-tabs')

    if(navTabs != null) {
      navTabs.addEventListener('click', (event) => this.tabChange(event))
    }
  },
  methods: {
    tabChange(e) {
      let tab = e.target
      let parentNavTab = tab.closest('ul')
      let parentTab = tab.closest('li')
      let pane = tab.href.split('#')[1]

      parentNavTab.querySelectorAll('li').forEach(li => li.classList.remove('active'))
      document.querySelectorAll('.tab-pane').forEach(div => div.style.display = 'none') 
      parentTab.classList += " active"
      
      document.querySelector('#' + pane).style.display = 'block'
    }
  }
}
</script>