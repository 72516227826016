<template>
  <div class='col-md-12'>
    <ul class='nav nav-pills compare-view'>
      <li data-value='all' class='active'><a href='#'>All Fields</a></li>
      <li data-value='different'><a href='#'>Differences Only</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CompareEvals",  
  data () {
    return {
      navPills: null
    }
  },
  mounted() {
    this.navPills = document.querySelector('.nav-pills')

    if(this.navPills != null) {
      this.navPills.addEventListener('click', (event) => this.pillChange(event))
    }
  },
  methods: {
    pillChange(e) {
      let pill = e.target
      let parentPill = pill.closest('li')
      let selected = parentPill.getAttribute('data-value')

      this.$el.querySelectorAll('li').forEach(li => li.classList.remove('active'))
      parentPill.classList += " active"
      
      document.querySelectorAll('tbody tr').forEach(function(tr) {
        let display = 'none'

        if(Array.from(tr.classList).includes('always')) {
          display = 'table-row'
        } else if(Array.from(tr.classList).includes(selected)) {
          display = 'table-row'
        }

        tr.style.display = display
      }) 
    }
  }
}
</script>
