<template>
  <div ref="drag-drop-component">
    <form ref="fileform" :class="componentClass">
      <span v-if="dragAndDropCapable">
        <i class="fa fa-lg fa-file"></i>
        <span class="drop-files">&nbsp;Drop files here...</span><span v-if="files.length > 0" class="badge">{{files.length}}</span>
      </span>
      <span v-else>
        <i class="fa fa-lg fa-ban"></i>
        <span class="drop-files">&nbsp;Click here to add files...</span><span v-if="files.length > 0" class="badge">{{files.length}}</span>
      </span>
      <input ref="fileinput" type="file" style="visibility:hidden" multiple />
    </form>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'
import FileId from './FileId.vue'
import csrf from '../mixins/csrfMixin.js'

const md5FromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
  
    reader.onload = (fileEvent) => {
      let binary = CryptoJS.lib.WordArray.create(fileEvent.target.result)
      const md5 = CryptoJS.MD5(binary)
      resolve(md5)
    }
    reader.onerror = () => {
      reject('FileReader error')
    }

    reader.readAsArrayBuffer(file)
  })
}

export default {
  name: "FileUploader",
  mixins: [csrf],
  props: {
    value: String
  },
  data() {
    return {
      csrfToken: null,
      dragAndDropCapable: false,
      files: [],
      highlighted: false
    }
  },
  mounted() {
    let form = this.$refs.fileform
    this.dragAndDropCapable = this.determineDragAndDropCapable()

    if(this.dragAndDropCapable) {
      ['drag', 'dragstart', 'dragend', 'drop'].forEach(function(evt) {
        form.addEventListener(evt, function(e) {
          e.preventDefault()
          e.stopPropagation()
        }.bind(this), false)
      }.bind(this))

      form.addEventListener('dragenter', function(e) {
        this.highlighted = true
        e.preventDefault()
        e.stopPropagation()
      }.bind(this))      

      form.addEventListener('dragover', function(e) {
        this.highlighted = true
        e.preventDefault()
        e.stopPropagation()
      }.bind(this))      

      form.addEventListener('dragleave', function(e) {
        this.highlighted = false
        e.preventDefault()
        e.stopPropagation()
      }.bind(this))      

      form.addEventListener('drop', function(e) {
        this.highlighted = false
        for(let i = 0; i < e.dataTransfer.files.length; i++) {
          var file = e.dataTransfer.files[i]

          this.files.push(file)
          this.submitFile(file)
        }

      }.bind(this))

      var fileInput = this.$refs.fileinput

      form.addEventListener('click', function(e) {
        fileInput.click()
      }.bind(this))      

      fileInput.addEventListener('change', function(e) {
        for(let i = 0; i < e.target.files.length; i++) {
          var file = e.target.files[i]

          this.files.push(file)
          this.submitFile(file)
        }
      }.bind(this))          
    }
  },
  computed: {
    componentClass() {
      let componentClass = "drag-drop"

      if(this.highlighted) {
        componentClass += " highlighted"
      }

      return componentClass
    },
  },
  methods: {
    determineDragAndDropCapable () {
      var div = document.createElement('div')

      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div))
              && 'FormData' in window
              && 'FileReader' in window
    },
    async submitFile(file) {
      await md5FromFile(file)
        .then(response => {
          const md5 = response
          const checksum = md5.toString(CryptoJS.enc.Base64)
          let url = `/file_uploads/new`
          let params = { file_upload: { filename: file.name, byte_size: file.size, checksum: checksum, content_type: file.type } }

          this.$secured.get(url, { params })
            .then((response) => {
              const signedId = response.data.signed_id
              const url = response.data.direct_upload.url
              const headers = response.data.direct_upload.headers

              this.$aws.put(url, file, { headers: headers })
                .then(() => {
                  let headers = { 'X-CSRF-Token': this.csrfToken }

                  this.$secured.post('/file_uploads', { file_upload: { attachment: signedId, filename: file.name, fileid: this.value } }, { headers: headers } )
                    .then(response => {
                      let index = this.files.indexOf(file)

                      if(index > -1) {
                        this.files.splice(index, 1)
                      }
                    })
                    .catch((error) => {
                      this.error = `Failed to post file upload to ERS (${error.message})`
                    })
                })
                .catch((error) => {
                  this.error = `Failed to post file to AWS (${error.message})`
                })
            })
            .catch((error) => {
              this.error = `Failed to get presigned URL from ERS (${error.message})`
            })    
        })
    }  
  }
}  
</script>

<style>
  form.drag-drop {
    display: block;
    height: 34px;
    width: 100%;
    background: #ccc;
    margin: auto;
    text-align: center;
    line-height: 34px;
    border-radius: 4px;
  }

  div.fileBox {
    border: 1px solid black;
    border-radius: 3px;
    margin: 20px;
  }

  div.fileBox > p {
    padding: 10px;
  }

  form.highlighted {
    background-color: darkgreen;
  }  
</style>