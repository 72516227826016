<template>
  <div class="input-group">
    <input v-model="fileSuffix" class="form-control" minlength="5" maxlength="5" @input="changed = true">
    <div class="input-group-btn">
      <button :class="commitButtonClass()" type="button" @click="commitChange" :disabled="commitDisabled"><i class="fa fa-check"></i></button>
      <button :class="cancelButtonClass()" type="button" @click="cancelChange" :disabled="cancelDisabled"><i class="fa fa-times"></i></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileSuffix",
  props: {
    value: String
  },
  data() {
    return {
      changed: false,
      fileSuffix: this.value
    }
  },
  watch: {
    value() {
      this.fileSuffix = this.value
    }
  },
  computed: {
    commitDisabled () {
      return !this.changed || this.fileSuffix.length !== 5
    },
    cancelDisabled () {
      return !this.changed
    }   
  },
  methods: {
    commitButtonClass () {
      var buttonClass = 'btn btn-outline-secondary'

      if(this.changed) {
        buttonClass += ' btn-success'
      }

      return buttonClass
    },
    cancelButtonClass () {
      var buttonClass = 'btn btn-outline-secondary'

      if(this.changed) {
        buttonClass += ' btn-danger'
      }

      return buttonClass
    },
    commitChange() {
      this.changed = false
      this.$emit('input', this.fileSuffix)
    },
    cancelChange() {
      this.changed = false
      this.fileSuffix = this.value
    }
  }
} 
</script>