<template>
  <div ref="fileupload" :draggable="!value.identified" class="thumbnail" @dragstart="dragStart">
    <div class="row">
      <div class="col-md-8">
        <img v-if="value.attachment.thumb_url" :src="value.attachment.thumb_url" :alt="value.attachment.filename" class="img-thumbnail" @click="$emit('preview')">
        <i v-else class="fa fa-2x fa-file img-thumbnail"></i>
      </div>

      <div class="col-md-4">
        <div class="btn-toolbar">
          <div class="btn-group pull-right">
            <a class="btn btn-default btn-sm" :href="value.attachment.download_url" target="_blank"><i class="fa fa-download"></i></a>
            <button v-if="value.identified && 1 == 0" class="btn btn-success btn-sm" @click="$emit('submit')"><i class="fa fa-link"></i></button>
            <button class="btn btn-danger btn-sm" @click="$emit('delete')"><i class="fa fa-trash"></i></button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-if="value.identified" class="col-md-12">
        <file-name v-model="value.filename" @input="$emit('input')"></file-name>
      </div>
    </div>
  </div>
</template>

<script>
import FileName from './FileName.vue'

export default {
  name: "FileUpload",
  components: {
    'file-name': FileName
  },
  props: {
    value: Object
  },
  data() {
    return {
    }
  },
  methods: {
    dragStart(e) {
      e.dataTransfer.setData('fileUploadId', this.value.id)
    }
  }
}  
</script>

<style>
  div.file-placeholder { 
    height: 200px;
    width: 200px;
    padding: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 3px;
    margin: 0 auto;
  }

  .center-items {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .equal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
  }
</style>