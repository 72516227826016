<template>
  <div>
    <span v-if="downloadStatus == 'unknown'">
      <i class="fa fa-question-circle is-small"></i>
    </span>

    <span v-if="downloadStatus == 'missing'">
      <i class="fa fa-times-circle is-small"></i>
    </span>

    <span v-if="downloadStatus == 'available'">
      <a :href="url"><i class="fa fa-download is-small"></i></a>
    </span>
  </div>
</template>

<script>
export default {
  name: "FileDownload",
  props: {
    id: String,
    url: String
  },
  data() {
    return {
      downloadStatus: 'unknown',
      error: null
    }
  },
  mounted() {
    this.inspectDownload()
  },
  methods: {
    inspectDownload() {
      var url = `/binaries/${this.id}.json`

      this.$secured.get(url)
        .then((response) => {
          if(response.data.attachment_valid) {
            this.downloadStatus = 'available'
          } else {
            this.downloadStatus = 'missing'          
          }
        })
        .catch((error) => {
          this.error = `Failed to get binary: ${error}`
        })     
    }
  }
} 
</script>