<template>
  <div>
    <input type="file" :id="field" :name="field" @change="fileSelected" />

    <button class="btn btn-default" @click.prevent="chooseFile()">
      <i class="fa fa-file">
      </i>&nbsp;{{title}}
    </button>
  </div>
</template>

<script>
export default {
  name: "FileChooser",
  props: {
    field: String,
    title: String
  },
  data() {
    return {
      input: null
    }
  },
  mounted() {
    this.input = this.$el.querySelector('input[type=file]')
    this.input.style.display = 'none'
  },
  methods: {
    chooseFile() {
      document.getElementById(this.field).click()
    },
    fileSelected(e) {
      let form = e.target.form
      form.submit()
    }
  }
}  
</script>