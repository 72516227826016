<template>
  <div>
    <button v-if="!isSorting" class="btn btn-default pull-right" @click="startSorting">Reorder</button>
    <button v-if="isSorting" class="btn btn-default pull-right" @click="finishSorting">Done</button>

    <ul v-if="isSorting" class="table">
      <draggable v-model="resources" @start="drag=true" @end="drag=false" @change="onChange">
        <li v-for="resource in resources" :key="resource.id">{{resource.filename}}</li>
      </draggable>
    </ul>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import csrf from '../mixins/csrfMixin.js'

export default {
  name: "SortResources", 
  mixins: [csrf],
  components: {
    'draggable': draggable
  },
  props: {
    category_id: Number
  },
  data() {
    return {
      resources: [],
      error: null,
      isSorting: false
    }
  },  
  mounted() {
    this.getResources()
  },
  methods: {
    onChange(event) {
      this.updateSortOrder()
    },
    startSorting() {
      this.isSorting = true
      document.getElementById('list-resources').style.display = 'none'
    },
    finishSorting() {
      location.reload()
    },
    async getResources() {
      let url = `/resources?category_id=${this.category_id}`
      await this.$secured.get(url)
        .then((response) => {
          this.resources = response.data
        })
        .catch((error) => {
          this.error = `Failed to get resources: ${error}`
          this.isLoading = false
        })       
    },
    updateSortOrder() {
      let url = `/resources/sort`
      let headers = { 'X-CSRF-Token': this.csrfToken }

      this.$secured.put(url, { ids: this.resources.map((resource) => resource.id )}, { headers: headers })
        .then((response) => {
          response
        })
        .catch((error) => {
          this.error = `Failed to sort resources: ${error}`
          this.isLoading = false
        })          
    }
  }
}  
</script>