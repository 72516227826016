export default {
  mounted() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  },
  data() {
    return {
      csrfToken: null
    }
  }
}
