<template>
  <div class="input-group">
    <input v-model="name" class="form-control" minlength="10" @input="changed = true">
    <span class="input-group-addon">.{{extension}}</span>
    <div class="input-group-btn">
      <button :class="commitButtonClass()" type="button" @click="commitChange" :disabled="commitDisabled"><i class="fa fa-check"></i></button>
      <button :class="cancelButtonClass()" type="button" @click="cancelChange" :disabled="cancelDisabled"><i class="fa fa-times"></i></button>
    </div>
  </div>
</template>

<script>

function filename(path) {
  var filename = path.substr(0, path.lastIndexOf('.'))

  return filename
}

function extension(path) {
  var ext = path.substr(path.lastIndexOf('.') + 1)

  return ext
}

export default {
  name: "FileName",
  props: {
    value: String
  },
  data() {
    return {
      changed: false,
      name: filename(this.value),
      extension: extension(this.value)
    }
  },
  watch: {
    value() {
      this.name = filename(this.value)
      this.extension = extension(this.value)
    }
  },
  computed: {
    commitDisabled () {
      return !this.changed || this.name.length === 0
    },
    cancelDisabled () {
      return !this.changed
    },
    fileName () {
      return [this.name, this.extension].join('.')
    }
  },
  methods: {
    commitButtonClass () {
      var buttonClass = 'btn btn-outline-secondary'

      if(this.changed) {
        buttonClass += ' btn-success'
      }

      return buttonClass
    },
    cancelButtonClass () {
      var buttonClass = 'btn btn-outline-secondary'

      if(this.changed) {
        buttonClass += ' btn-danger'
      }

      return buttonClass
    },
    commitChange() {
      this.changed = false
      this.$emit('input', this.fileName)
    },
    cancelChange() {
      this.changed = false
      this.name = filename(this.value)
    }
  }
} 
</script>