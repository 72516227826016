<template>
  <div>
    {{message}}
  </div>
</template>

<script>
export default {
  data () {
    return {
      message: null
    }
  }  
}
</script>

<style scoped>
  p {
    font-size: 2em;
    text-align: center;
  }
</style>
