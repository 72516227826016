<template>
  <div ref="template" :class="componentClass" @dragenter="dragEnter" @dragover="dragEnter" @dragleave="dragLeave" @drop="drop">
    <i v-if="checked" style="color: #4CBB17" class="fa fa-check"></i>&nbsp;<b>{{value.name}}</b>
  </div>
</template>

<script>
export default {
  name: "FileUploadTemplate",
  props: {
    value: Object
  },
  data() {
    return {
      highlighted: false
    }
  },
  mounted() {
  },
  computed: {
    componentClass() {
      let componentClass = "file-upload-template"

      if(this.highlighted) {
        componentClass += " highlighted"
      }

      return componentClass
    },
    checked() {
      return this.$parent.identifiedFileUploads.find(fileUpload => fileUpload.file_upload_template_id === this.value.id) !== undefined
    }
  },
  methods: {
    dragEnter(e) {
      this.highlighted = true
      e.preventDefault()
    },
    dragLeave(e) {
      this.highlighted = false
      e.preventDefault()
    },    
    drop(e) {
      let fileUploadId = e.dataTransfer.getData('fileUploadId')

      this.highlighted = false
      this.$emit('update', fileUploadId, { file_upload_template_id: this.value.id })

      e.preventDefault()
    }
  }
}  
</script>

<style>
  div.file-upload-template {
    text-align: center;
    height: 30px;
    width: 100%;
    padding: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 12px;
    margin: 0 auto;
    margin-bottom: 10px;    
  }

  div.highlighted {
    background-color: darkgreen;
  }
</style>
