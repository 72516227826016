/* eslint no-console: 0 */

require('jquery')

import Vue from 'vue/dist/vue.esm'
import draggable from 'vuedraggable'
import Rails from '@rails/ujs'
import router from '../router'
import { BackendPlugin } from '../backend'
import App from '../components/App.vue'
import CompareEvals from '../components/CompareEvals.vue'
import FileChooser from '../components/FileChooser.vue'
import FileDownload from '../components/FileDownload.vue'
import FileId from '../components/FileId.vue'
import FileName from '../components/FileName.vue'
import FileSuffix from '../components/FileSuffix.vue'
import FileUpload from '../components/FileUpload.vue'
import FileUploader from '../components/FileUploader.vue'
import FileUploadManager from '../components/FileUploadManager.vue'
import LocationDropdown from '../components/LocationDropdown.vue'
import ShowEvaluation from '../components/ShowEvaluation.vue'
import SortResources from '../components/SortResources.vue'
Rails.start()

Vue.use(BackendPlugin, { router })

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#app',
    components: {
      'app': App,
      'compare-evals': CompareEvals,
      'draggable': draggable,
      'file-chooser': FileChooser,
      'file-download': FileDownload,
      'file-id': FileId,
      'file-name': FileName,
      'file-upload': FileUpload,
      'file-uploader': FileUploader,
      'file-suffix': FileSuffix,
      'file-upload-manager': FileUploadManager,
      'location-dropdown': LocationDropdown,
      'show-evaluation': ShowEvaluation,
      'sort-resources': SortResources
    }
  })
})


